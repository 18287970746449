import { mediaMMdMin } from 'helpers/breakpoints';
import styled from 'styled-components';

const CloseModalButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  z-index: 9999;
  top: 12px;
  right: 12px;

  display: block;
  overflow: visible;

  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;

  cursor: pointer;
  transition: color 0.2s;
  text-decoration: none;
  font-size: 1.2em;

  border: 0;
  outline: 0;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 32 32'%3E%3Ccircle cx='16' cy='16' r='16' fill='%23F5F7F9'/%3E%3Crect width='2' height='14' x='10' y='11.414' fill='%235E696A' rx='1' transform='rotate(-45 10 11.414)'/%3E%3Crect width='2' height='14' x='11.414' y='21.314' fill='%235E696A' rx='1' transform='rotate(-135 11.414 21.314)'/%3E%3C/svg%3E");

  ${mediaMMdMin} {
    top: 20px;
    right: 20px;
  }

  &:focus {
    outline: 0;
  }

  &:hover,
  &:focus {
    color: #2b2e38;
  }
`;

export default CloseModalButton;
