import { isValidElement, ReactNode, useCallback, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { mediaMMdMin, mediaMSmMax } from 'helpers/breakpoints';
import useRudderStackTrack from 'hooks/useRudderStackTrack';
import ModalTitle from './ModalTitle';
import CloseModalButton from './CloseModalButton';
import {
  basicFadeInAnimation,
  getOnRightStyles,
  getPaddingVariantStyles,
  getSizeVariantStyles,
  getTrayStyles,
  PaddingType,
  SizeType,
} from './styles';
import Backdrop from './Backdrop';

export interface ModalProps {
  isVisible?: boolean;
  isTray?: boolean;
  isOverlay?: boolean;
  titleBackDrop?: boolean;
  title?: string;
  size?: SizeType;
  fullHeight?: boolean;
  isOnRight?: boolean;
  notScrollAble?: boolean;
  onClose?: () => void;
  hideCloseButton?: boolean;
  content100?: boolean;
  padding?: PaddingType;
  overridePadding?: PaddingType;
  clickOutsideToClose?: boolean;
  trackingName: string;
}

export type ModalContentProps<CustomProps> = {
  onCloseModal?: () => void;
} & CustomProps;

interface Props {
  modalProps: ModalProps;
  modalContent: ReactNode;
  isVisible: boolean;
  handleCloseModal: () => void;
  modalName: string;
}

export default function Modal({ modalProps, modalContent, isVisible, handleCloseModal, modalName }: Props) {
  const {
    overridePadding = 'none',
    size = 'large',
    isTray = true,
    isOverlay = false,
    hideCloseButton = false,
    isOnRight = false,
    title = '',
    titleBackDrop,
    notScrollAble = false,
    onClose = null,
    fullHeight,
    clickOutsideToClose = true,
  } = modalProps ?? {};

  const modalContentRef = useRef(null);

  const rudderStackTrack = useRudderStackTrack();

  const onCloseModal = useCallback(() => {
    handleCloseModal();
    onClose?.();
    if (isValidElement(modalContent) && typeof modalContent.type === 'function') {
      rudderStackTrack('modal_close_event', {
        name: modalProps.trackingName,
        modalProps: Object.fromEntries(
          Object.entries(modalContent.props).filter(([, value]) =>
            ['string', 'boolean', 'number'].includes(typeof value)
          )
        ),
      });
    }
  }, [handleCloseModal, onClose]);

  return (
    <>
      <CSSTransition in={isVisible} timeout={300} classNames="REMODAL_overlay_transition" unmountOnExit mountOnEnter>
        <Backdrop role="presentation" />
      </CSSTransition>
      <CSSTransition in={isVisible} timeout={300} classNames="REMODAL_transition" unmountOnExit mountOnEnter>
        <Container
          size={size}
          notScrollAble={notScrollAble}
          fullHeight={fullHeight}
          isOnRight={isOnRight}
          isOverlay={isOverlay}
          isTray={isTray}
          overridePadding={overridePadding}
          onClick={(event) => {
            // Stop propagation to prevent closing parent modal
            event.stopPropagation();
            if ((event?.target as any)?.closest('.REMODAL_content') || !clickOutsideToClose) {
              // if target is inside modal content or clickOutsideToClose is false, do not close modal
              return;
            }
            onCloseModal();
          }}
        >
          <div
            className="REMODAL_content"
            tabIndex={-1}
            ref={modalContentRef}
            // TODO data-testid={`component-${modalComponent.name}-modal`}
          >
            {!title && !hideCloseButton && (
              <CloseModalButton
                onClick={onCloseModal}
                data-testid={`close-modal-button${modalName ? `-${modalName}` : ''}`}
              />
            )}

            {title && <ModalTitle title={title} titleBackDrop={titleBackDrop} onCloseModal={onCloseModal} />}

            <div className="REMODAL_scroll">{modalContent}</div>
          </div>
        </Container>
      </CSSTransition>
    </>
  );
}

type ContainerProps = Pick<
  ModalProps,
  'isOnRight' | 'isTray' | 'isOverlay' | 'size' | 'notScrollAble' | 'fullHeight' | 'overridePadding'
>;

const Container = styled.div<ContainerProps>`
  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;
  position: fixed;
  inset: 0;
  z-index: 10;

  display: flex;
  justify-content: center;
  align-items: center;

  ${mediaMSmMax} {
    padding: 1em;
  }
  ${mediaMMdMin} {
    align-items: center;
  }

  /* Basic appearance animation */
  ${basicFadeInAnimation}

  .REMODAL_content {
    position: relative;

    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;

    background: #fff;
    border-radius: 14px;
    border: solid 1px #dbdfe5;
  }

  ${({ size }) => getSizeVariantStyles(size)}

  .REMODAL_scroll {
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;

    ${({ overridePadding }) => getPaddingVariantStyles(overridePadding)}

    ${({ notScrollAble }) =>
      notScrollAble &&
      css`
        overflow: inherit !important;
        height: 100% !important;
      `}
  }

  ${({ isTray }) => getTrayStyles(isTray)};
  ${({ isOnRight }) => getOnRightStyles(isOnRight)};

  ${({ fullHeight }) =>
    fullHeight &&
    css`
      ${mediaMMdMin} {
        .REMODAL_content {
          min-height: 80%;
        }
      }
    `};

  ${({ isOverlay }) =>
    isOverlay &&
    css`
      ${mediaMSmMax} {
        .REMODAL_content {
          max-height: unset;
          height: 100%;
          border-radius: unset;
          border: none;
          max-width: unset;
        }

        /* Reset to basic animation */
        ${basicFadeInAnimation}
      }
    `};
`;
