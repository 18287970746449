import { createContext } from 'react';

const ModalContext = createContext<{
  closeModal: () => void;
  isModalOpen: boolean;
  setModalTitleBackdrop: (titleBackDrop: boolean) => void;
  setModalTitle: (title: string) => void;
}>(null);

export default ModalContext;
