import styled from 'styled-components';

const Backdrop = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(10, 37, 47, 0.6);
  z-index: 10;

  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  &.REMODAL_overlay_transition {
    &-enter {
      opacity: 0;
    }
    &-enter-active {
      opacity: 1;
      background: rgba(10, 37, 47, 0.6);
      transition: opacity 300ms ease-in-out;
    }
    &-exit {
      opacity: 1;
    }
    &-exit-active {
      opacity: 0;
      background: rgba(10, 37, 47, 0.6);
      transition: opacity 300ms ease-in-out;
    }
  }
`;

export default Backdrop;
