import { Box } from 'components/primitives/box';
import { FlexAlignCenter } from 'components/primitives/flex';
import { uniformSizing } from 'components/primitives/mixins';
import { mediaMLgMin, mediaMMdMax } from 'helpers/breakpoints';
import styled, { css, keyframes } from 'styled-components';
import { ArrowNextIcon, CupIcon } from './icons';

export const layoutMaxWidth = css`
  max-width: calc(1292px + 26px + 26px);
  margin: 0 auto;
  padding: 0 26px;

  ${mediaMMdMax} {
    padding: 0 21px;
  }
`;

export const sectionStyles = css`
  padding: 35px 0 35px;
  border-bottom: 1px solid #dbdfe5;

  ${mediaMLgMin} {
    padding: 60px 0;
  }
`;

export const Section = styled.div`
  ${sectionStyles}
`;

export const SubHeading = styled.h2`
  font-weight: 600;
  font-size: 28px;

  ${mediaMMdMax} {
    font-size: 16px;
  }
`;

export const ContainerGrid1to4 = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 0 96px;
  /* padding: 56px 0; */

  ${sectionStyles}

  ${mediaMMdMax} {
    gap: 20px;
    grid-template-columns: 1fr;
  }
`;

export const ToggleLink = styled.div`
  padding-top: 20px;
  color: #0751c2;
  font-weight: 500;
  cursor: pointer;
`;

export const Separator = styled.hr`
  display: block;
  height: 1px;
  width: 100%;
  background-color: #dbdfe5;
  border: none;
  margin: 35px 0 35px;
`;

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
}
`;

export const AnimatedSkeleton = styled(Box)`
  min-height: 12px;
  position: relative;
  overflow: hidden;
  background-color: #f1f1f1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    transform: translateX(-100%);
    animation: ${shimmer} 2s infinite;
  }
`;

interface SizeProp {
  size?: string;
}

export const AnimatedSkeletonCircle = styled(AnimatedSkeleton)<SizeProp>`
  border-radius: 50%;
  ${uniformSizing}
`;

const BadgeBase = styled(FlexAlignCenter)<{
  topOwner?: boolean;
  newOwner?: boolean;
  newCaptain?: boolean;
}>`
  gap: 3px;
  font-size: 9px;
  font-weight: 700;
  line-height: 8px;
  padding: 5px 6px;
  border-radius: 4px;

  color: ${({ newCaptain, newOwner, topOwner }) => (newCaptain || newOwner ? '#fff' : topOwner && '#154353')};

  background-color: ${({ newCaptain, newOwner, topOwner }) => getBgColor(newCaptain, topOwner, newOwner)};
`;

const getBgColor = (newCaptain, topOwner, newOwner) => {
  if (newCaptain) {
    return '#004e60';
  }
  if (topOwner) {
    return '#f4c064';
  }
  return newOwner && '#0e7873';
};

export const Badge = ({ topOwner = false, newOwner = false, newCaptain = false, iconOnly = false, ...other }) => {
  if (!topOwner && !newOwner && !newCaptain) {
    return null;
  }
  return (
    // topOwner trumps newOwner
    <BadgeBase topOwner={topOwner} newOwner={newOwner && !topOwner} newCaptain={newCaptain} {...other}>
      {topOwner ? (
        <>
          <CupIcon /> {!iconOnly && 'TOP OWNER'}
        </>
      ) : (
        newOwner && 'NEW OWNER'
      )}
      {newCaptain && 'NEW CAPTAIN'}
    </BadgeBase>
  );
};

export const StyledArrowNextIcon = styled(ArrowNextIcon)<{ className: string }>`
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 8px;
  left: unset;
  transform: translateY(-50%);
  cursor: pointer;
  transition: opacity 0.3 ease-in-out;
  width: 47px;
  height: 47px;

  &.swiper-button-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${mediaMMdMax} {
    display: none;
  }
`;

export const StyledArrowPrevIcon = styled(StyledArrowNextIcon)`
  left: 8px;
  transform: translateY(-50%) rotate(180deg);
`;
