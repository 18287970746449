import ClientOnlyPortal from 'components/ClientOnlyPortal';
import { useCallback, useMemo, useState } from 'react';
import useRudderStackTrack from 'hooks/useRudderStackTrack';
import ModalContext from './context';
import Modal, { ModalProps } from './Modal';

export default function useModal(modalContent, modalProps: ModalProps, modalName?: string) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [overrideModalProps, setOverrideModalProps] = useState<ModalProps>(null);
  const rudderStackTrack = useRudderStackTrack();

  const openModal = useCallback(() => {
    setIsModalOpen(true);
    document.documentElement.classList.add('no-scroll');

    rudderStackTrack('modal_open_event', {
      name: modalProps.trackingName,
      modalProps: Object.fromEntries(
        Object.entries(modalContent.props).filter(([, value]) => ['string', 'boolean', 'number'].includes(typeof value))
      ),
    });
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    document.documentElement.classList.remove('no-scroll');
    setOverrideModalProps(null);
  }, []);

  const setModalTitleBackdrop = useCallback(
    (titleBackDrop: boolean) => {
      setOverrideModalProps({ ...overrideModalProps, titleBackDrop });
    },
    [overrideModalProps, setOverrideModalProps]
  );

  const setModalTitle = useCallback(
    (title: string) => {
      setOverrideModalProps({ ...overrideModalProps, title });
    },
    [overrideModalProps, setOverrideModalProps]
  );

  const providerValue = useMemo(
    () => ({ isModalOpen, closeModal, setModalTitleBackdrop, setModalTitle }),
    [isModalOpen, closeModal, setModalTitleBackdrop, setModalTitle]
  );

  const modal = (
    // @ts-ignore
    <ClientOnlyPortal selector="#modalPortal">
      <ModalContext.Provider value={providerValue}>
        <Modal
          modalProps={{ ...modalProps, ...overrideModalProps }}
          modalContent={modalContent}
          isVisible={isModalOpen}
          handleCloseModal={closeModal}
          modalName={modalName}
        />
      </ModalContext.Provider>
    </ClientOnlyPortal>
  );

  return { openModal, closeModal, modal, isModalOpen };
}
