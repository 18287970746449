import { mediaMMdMin, mediaMSmMax } from 'helpers/breakpoints';
import { css } from 'styled-components';
import CloseModalButton from './CloseModalButton';

export const basicFadeInAnimation = css`
  &.REMODAL_transition {
    &-enter {
      opacity: 0;
      .REMODAL_content {
        transform: scale(0.9);
      }
    }
    &-enter-active {
      opacity: 1;
      transition: opacity 300ms ease-in-out;
      .REMODAL_content {
        transform: scale(1);
        transition: transform 300ms ease-in-out;
      }
    }
    &-exit {
      opacity: 1;
      .REMODAL_content {
        transform: scale(1);
      }
    }
    &-exit-active {
      opacity: 0;
      transition: opacity 300ms ease-in-out;
      .REMODAL_content {
        transform: scale(0.9);
        transition: transform 300ms ease-in-out;
      }
    }
  }
`;

const defaultPadding = css`
  .REMODAL_scroll {
    padding: 1.5em;

    ${mediaMMdMin} {
      padding: 3em 3.6em;
    }
  }
`;

const sizeVariants = {
  large: css`
    .REMODAL_content {
      ${mediaMMdMin} {
        max-width: 960px;
      }
    }

    ${defaultPadding}
  `,
  small: css`
    .REMODAL_content {
      ${mediaMMdMin} {
        max-width: 573px;
      }
    }

    .REMODAL_scroll {
      padding: 36px;
    }
  `,
  medium: css`
    .REMODAL_content {
      ${mediaMMdMin} {
        max-width: 653px;
      }
    }
    ${defaultPadding}
  `,
  'medium-large': css`
    .REMODAL_content {
      ${mediaMMdMin} {
        max-width: 750px;
      }
    }
  `,
  kindaSmall: css`
    .REMODAL_content {
      ${mediaMMdMin} {
        max-width: 420px;
      }
    }

    .REMODAL_scroll {
      padding: 2em;
    }
  `,
};
export type SizeType = keyof typeof sizeVariants;
export const getSizeVariantStyles = (size: SizeType) => sizeVariants[size];

const paddingVariants = {
  none: null,
  extraSmall: css`
    padding: 0.5em !important;
  `,
  small: css`
    padding: 1.5em !important;
  `,
};
export type PaddingType = keyof typeof paddingVariants;
export const getPaddingVariantStyles = (padding: PaddingType) => paddingVariants[padding];

export const getTrayStyles = (isTray = true) =>
  isTray &&
  css`
    /* isTray on mobile by default */
    ${mediaMSmMax} {
      align-items: flex-end;
      padding: 0;
      overflow: hidden;

      .REMODAL_content {
        overflow: hidden;
        border: none;
        max-height: calc(100% - 7vh);
        box-shadow: 0 8px 28px rgb(0 0 0 / 28%);
        border-radius: 14px 14px 0 0;

        display: flex;
        flex-direction: column;
      }

      .REMODAL_scroll {
        padding: 1.5em;
        overflow-y: auto;
        height: 100%;
      }

      &.REMODAL_transition {
        &-enter {
          .REMODAL_content {
            transform: translateY(100%);
          }
        }
        &-enter-active {
          .REMODAL_content {
            transform: translateY(0%);
          }
        }
        &-exit {
          .REMODAL_content {
            transform: translateY(0%);
          }
        }
        &-exit-active {
          .REMODAL_content {
            transform: translateY(100%);
          }
        }
      }
    }
  `;

export const getOnRightStyles = (isOnRight) =>
  isOnRight &&
  css`
    ${mediaMMdMin} {
      justify-content: flex-end;
      align-items: stretch;

      .REMODAL_content {
        max-height: 100%;
        height: 100%;
        width: unset;
        border-radius: unset;
        border: none;
      }

      .REMODAL_scroll {
        padding: 40px 75px;
      }

      ${CloseModalButton} {
        right: unset;
        position: sticky;
        left: 15px;
        top: 15px;
      }

      &.REMODAL_transition {
        &-enter {
          .REMODAL_content {
            transform: translateX(100%);
          }
        }
        &-enter-active {
          .REMODAL_content {
            transform: translateX(0%);
          }
        }
        &-exit {
          .REMODAL_content {
            transform: translateX(0%);
          }
        }
        &-exit-active {
          .REMODAL_content {
            transform: translateX(100%);
          }
        }
      }
    }
  `;
