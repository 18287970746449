import { mediaMSmMax } from 'helpers/breakpoints';
import { ReactNode } from 'react';
import styled from 'styled-components';
import CloseModalButton from './CloseModalButton';

type ModalTitleProps = {
  title: ReactNode;
  titleBackDrop?: boolean;
  onCloseModal: () => void;
};

export default function ModalTitle({ title, titleBackDrop, onCloseModal }: ModalTitleProps) {
  return (
    <ModalTitleStyled style={{ background: titleBackDrop ? 'rgba(10,37,47,.6)' : 'unset' }}>
      <div style={typeof title !== 'string' ? { height: 55 } : {}}>
        <CloseModalButton onClick={onCloseModal} />
        {typeof title === 'string' && <Heading>{title}</Heading>}
      </div>
    </ModalTitleStyled>
  );
}

const Heading = styled.div`
  font-weight: bold;
  font-size: 24px;

  ${mediaMSmMax} {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  }
`;

const ModalTitleStyled = styled.div`
  /* only on scrolled */
  padding: 1em 2em;
  box-shadow: 0 2px 5px -2px rgb(0 0 0 / 15%);

  ${CloseModalButton} {
    top: 0.1em;
    right: 0.1em;
  }
`;
